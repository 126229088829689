.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 1.3rem;
          font-weight: 600;
        }
      }
    }
  }
}
