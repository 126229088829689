.action-button {
  border: none;
  border: 0.1rem solid #111111;
  background-color: transparent;
  display: flex;
  gap: 0.1rem;
  border-radius: 50%;
  margin: auto;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;

  > span {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #111111;
    border-radius: 50%;
  }
}
