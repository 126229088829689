.dashboard-layout-wrapper {
  background-color: #f0f0f0;
  height: 100vh;
  display: flex;

  * {
    font-size: 1.6rem;

    @media only screen and (min-width: 900px) {
      font-size: 1.4rem;
    }
  }

  > div {
    height: 100%;
    overflow-y: auto;
  }

  > .sidebar-wrapper {
    width: 32rem;
    // background-color: rgb(248, 249, 250);
    background-color: rgb(1,21,59);
    box-shadow: 0px 0.5rem 2rem rgba(0, 0, 0, 0.04);
    margin-left: -32rem;
    position: fixed;
    z-index: 99;

    @media only screen and (min-width: 900px) {
      margin-left: 0;
      position: static;

      .close-nav-button-wrapper {
        display: none !important;
      }
    }

    &.toggle-navbar {
      margin-left: 0;

      @media only screen and (min-width: 900px) {
        margin-left: -32rem;
      }
    }

    .sidebar-top-wrapper {
      height: 18rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.6rem;

      @media only screen and (min-width: 900px) {
        height: 18rem;
      }

      > .school-image {
        width: 8rem;
        height: 8rem;
      }

      p {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;
        color: rgb(248, 249, 250);
      }
    }

    .sidebar-links-wrapper {
      > a {
        text-decoration: none;
        // color: #333333;
        color: rgb(248, 249, 250);
        display: flex;
        width: 100%;
        padding: 1.3em 3em;
        gap: 1em;
        align-items: center;
        position: relative;

        &:hover,
        &.active {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(54, 127, 169, 0.3);
            border-left: 0.5rem solid rgb(54, 127, 169);
          }
        }
      }
    }
  }

  > .content-wrapper {
    width: 100%;

    > .navbar {
      box-shadow: 0.3rem 0px 2rem rgba(0, 0, 0, 0.04);
      position: sticky;
      width: 100%;
      top: 0;
      z-index: 2;

      .navbar-nav {
        .dropdown-item {
          font-size: 1.2rem;

          .nav-dropdown-link {
            display: block;
            text-decoration: none;
            color: #000000;
            font-size: 1.2rem;
          }
        }
      }
    }

    > .content-inner-wrapper {
      padding: 3rem 1rem;

      @media only screen and (min-width: 900px) {
        padding: 3rem;
      }
    }
  }
}
