.auth-layout-wrapper {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    height: 99vh;
  }

  .auth-layout-form-wrapper {
    height: 100%;
    overflow: auto;

    @media only screen and (min-width: 900px) {
      width: 70.4rem;
      box-shadow: 0px 1.5rem 9rem rgba(0, 0, 0, 0.03);
    }
  }

  .auth-layout-image-wrapper {
    width: 100%;
    height: 100%;
    overflow: auto;

    @media only screen and (min-width: 900px) {
      width: calc(100% - 70.4rem);
    }

    .auth-layout-image-inner-wrapper {
      > div {
        &:first-child {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 4rem 0;

          @media only screen and (min-width: 900px) {
            display: block;
            margin: 10.77rem auto 7rem 9.7rem;
          }

          > img {
            width: 9rem;
            height: 9rem;
          }
        }

        &:last-child {
          width: 60rem;
          height: 33.76rem;
          margin-left: 6.7rem;
          object-fit: cover;
          overflow: hidden;
          display: none;

          @media only screen and (min-width: 900px) {
            display: block;
          }

          > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
