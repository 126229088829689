.custom-image-preview {
  max-width: 25rem;
  height: 30rem;
  overflow: hidden;
  border: 0.2rem solid rgba(84, 95, 125, 0.15);
  border-radius: 1rem;
  background-color: rgba(#000000, 0.5);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  p {
    font-size: 7.5rem !important;
    font-weight: 600;
    color: #ffffff;
    transform-origin: 3rem 0;
    position: absolute;
    bottom: 0;
    transform: rotate(-50deg);
    white-space: nowrap;
  }

  .reset-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
