.profile-image-wrapper {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  border: 0.1rem solid #ffffff;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
