.view {
  &__options {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media only screen and (min-width: 1290px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__tabs {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }

  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
    gap: 10px;
    p {
      font-size: 18px;
      color: var(--primary-color);
    }
  }

  &__questions {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 10px 0;
    justify-content: center;
    gap: 15px;
    @media only screen and (min-width: 800px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: start;
    }
    @media only screen and (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: start;
    }

    &_heading {
      font-size: 18px;
      font-weight: 600;
      margin: 20px 0;
    }

    &_container {
      // width: 800px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      padding: 20px 30px;
      // width: 400px;
      // min-height: 200px;
    }

    &_question {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      // font-weight: 500;
    }

    &_answer {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .spinner_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 100px 0;
    gap: 10px;
    p {
      font-size: 18px;
      color: var(--primary-color);
    }
  }

  .auth_select_container {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .auth_select {
    width: 100px;

    @media only screen and (min-width: 600px) {
      width: 150px;
    }
  }

  .placeholder_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 100px 0;
    .icon {
      font-size: 80px;
      width: 50px;
      height: 50px;
    }
    .heading {
      font-size: 30px;
      margin-top: 20px;
      font-weight: 700;
      color: var(--secondary-color);
    }
  }

  &__questions {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: 30px 0;
    justify-content: center;
    gap: 15px;
    @media only screen and (min-width: 800px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: start;
    }
    @media only screen and (min-width: 1600px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      justify-content: start;
    }

    &_container {
      // width: 800px;
      background: #ffffff;
      border: 1px solid #c2c2c2;
      border-radius: 5px;
      padding: 20px 30px;
      // width: 400px;
      // min-height: 200px;
    }

    &_question {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: 100%;
      // font-weight: 500;
    }
    &_answer {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      width: 100%;
      // font-weight: 500;
    }
  }

  .create_question {
    // padding: 50px 0;
    &_select {
      width: 100px;
      // height: 100px;
      @media only screen and (min-width: 600px) {
        width: 100%;
      }
    }

    &_label {
      margin-top: 20px;
      margin-bottom: 5px;
      // color: var(--secondary-color);
    }

    &_question {
      font-size: 15px;
      line-height: 20px;
      margin-top: 10px;
      font-weight: 500;
    }
    &_answer {
      font-size: 15px;
      line-height: 20px;
      margin-top: 5px;
      font-weight: 500;
    }
  }

  .answer_text {
    font-size: 14px;
    line-height: 18px;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
  }
}

.warning_text {
  font-size: 12px;
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  line-height: 20px;
  @media only screen and (min-width: 1024px) {
    font-size: 16px;
  }
}

.assignment_submitted {
  opacity: 40%;
}

.assignment_submitted_text {
  opacity: 40%;
  position: absolute;
  font-size: 50px;
  color: red;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.objective {
  position: relative;
}
