.home-card-wrapper {
  text-decoration: none;
  color: #000000;
  height: 12rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 600px) {
    height: 20rem;
  }

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 1rem;
    background-color: rgb(1,21,59);
  }

  &.blue {
    &::before {
      background-color: blue;
    }
  }

  &.orange {
    &::before {
      background-color: orange;
    }
  }

  &.red {
    &::before {
      background-color: red;
    }
  }

  &.green {
    &::before {
      background-color: green;
    }
  }

  &.pink {
    &::before {
      background-color: pink;
    }
  }

  &.purple {
    &::before {
      background-color: purple;
    }
  }

  @media only screen and (min-width: 600px) {
    height: 15rem;
  }

  h3,
  span {
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 700;

    @media only screen and (min-width: 600px) {
      font-size: 3rem;
      line-height: 4.5rem;
    }
  }

  &.is-badge {
    width: 100%;
    color: #ffffff;
    background-color: rgb(1,21,59);

    @media only screen and (min-width: 768px) {
      max-width: 25rem;
    }

    &.blue {
      background-color: blue;
    }

    &.orange {
      background-color: orange;
    }

    &.red {
      background-color: red;
    }

    &.green {
      background-color: green;
    }

    &.pink {
      background-color: pink;
    }

    &.purple {
      background-color: purple;
    }

    > svg {
      font-size: 3rem;
    }

    p {
      margin-top: 2rem;
      font-size: 2rem;
      text-align: center;
    }
  }
}
